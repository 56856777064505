<template>
  <v-app-bar app dense clipped-left>
    <v-img
      max-height="100"
      max-width="100"
      src="./../assets/KommONE.svg"
    ></v-img>
    
    <v-spacer />
    <div>
      <v-breadcrumbs :items="getCurrentNav">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-spacer />
    <v-spacer />
    <div class="text-right">
      <v-btn class="mx-1" small fab dark color="primary" v-on:click="logout">
        <v-img max-height="25" max-width="25" src="./../assets/logout.png">
        </v-img>
      </v-btn>
    </div> </v-app-bar
></template>

<script>
export default {
  name: "TopBar",
  props: {
    navpos: {
      text: String,
      disabled: Boolean,
      href: String
    }    ,
    
  },

  data() {
    return {
      nav: this.navpos,
    
    };
  },
  methods: {
    logout() {
      try {
        this.$store.dispatch("logout");
        this.$router.push({ path: "/" });
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  },
  computed: {
    getCurrentNav() {
      return this.nav;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  margin: auto;
  width: 70%;
  padding: 10px;
  text-align: center;
}
</style>
