<template>
  <v-app app>
    <top-bar :navpos="nav" />
    <v-navigation-drawer app permanent clipped>
      <div>
        <v-treeview
          :items="tree_nav"
          hoverable
          activatable
          item-key="id"
          open-on-click
          class="mt-1 text-left"
          @update:active="goto"
        >
          <template slot-scope="{ item }">
            <a @click="goto(item)">{{ item.goal }}</a>
          </template>

          <template v-slot:prepend="{ item }">
            <v-icon
              color="red lighten-2"
              v-if="item.implemented == false"
              v-text="`mdi-alert`"
            ></v-icon>
          </template>
        </v-treeview>
      </div>
    </v-navigation-drawer>

    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      {{ active_system_bezeichnung }}
      <v-spacer />
      {{ user.userName }}
    </v-footer>
  </v-app>
</template>

<script>
import TopBar from "../../../components/TopBar.vue";
const treeContent = [
  {
    id: "0",
    name: "Übersicht",
    goal: "old/architekturUebersicht",
    children: [],
    implemented: true
  },
  {
    id: "1",
    name: "Kontext",

    children: [
      {
        id: "1.1",
        name: "Fachlicher Kontext",
        goal: "old/fachlicherKontext",
        children: [
          {
            id: "1.1.1",
            name: "Anforderungen",
            goal: "old/fachlicheAnforderungen",
            children: [],
            implemented: true
          },
          {
            id: "1.1.2",
            name: "Akteure",
            goal: "old/akteure",
            children: [],
            implemented: true
          }
        ]
      },
      {
        id: "1.2",
        name: "System Kontext",
        goal: "old/systemKontext",
        children: [
          {
            id: "1.4",
            name: "Schnittstellen",
            goal: "old/schnittstellen",
            children: [],
            implemented: true
          }
        ]
      }
    ]
  },
  {
    id: "2",
    name: "Lösungsstrategie",
    goal: "old/loesungsstrategie",
    children: [],
    implemented: false
  },
  {
    id: "3",
    name: "Technologie",
    goal: "old/technologie",
    children: [
      {
        id: "3.1",
        name: "Technologieliste",
        goal: "old/technologieliste",
        children: [],
        implemented: true
      },
      {
        id: "3.2",
        name: "Bibliothekliste",
        goal: "old/bibliothekliste",
        children: [],
        implemented: true
      }
    ]
  },
  {
    id: "4",
    name: "Netzwerkzonen",
    goal: "old/netzwerkzonen",
    children: [],
    implemented: false
  },
  {
    id: "5",
    name: "Konzepte",
    goal: "old/konzepte",
    children: [
      {
        id: "5.1",
        name: "Security",
        goal: "old/security",
        children: [
          {
            id: "5.1.1",
            name: "Benutzerverwaltung",
            goal: "old/benutzerverwaltung",
            children: [],
            implemented: false
          },
          {
            id: "5.1.2",
            name: "Autorisierung",
            goal: "old/autorisierung",
            children: [],
            implemented: false
          },
          {
            id: "5.1.3",
            name: "Authentifizierung",
            goal: "old/authentifizierung",
            children: [],
            implemented: false
          }
        ],
        implemented: false
      },
      {
        id: "5.2",
        name: "Logging",
        goal: "old/logging",
        children: [],
        implemented: false
      },
      {
        id: "5.3",
        name: "Mandantenfähigkeit",
        goal: "old/mandantenfaehigkeit",
        children: [],
        implemented: false
      },
      {
        id: "5.4",
        name: "Build",
        goal: "old/build",
        children: [],
        implemented: false
      },
      {
        id: "5.5",
        name: "Verfügbarkeit",
        goal: "old/verfuegbarkeit",
        children: [],
        implemented: false
      },
      {
        id: "5.6",
        name: "Versionierung",
        goal: "old/versionierung",
        children: [],
        implemented: false
      },
      {
        id: "5.7",
        name: "Releasemanagement",
        goal: "old/releasemanagement",
        children: [],
        implemented: false
      },
      {
        id: "5.8",
        name: "Changemanagement",
        goal: "old/changemanagement",
        children: [],
        implemented: false
      }
    ],
    implemented: false
  },
  {
    id: "6",
    name: "Architekturentscheidungen",
    goal: "old/architekturentscheidungen",
    children: [],
    implemented: false
  },
  {
    id: "7",
    name: "Risiken",
    goal: "old/risiken",
    children: [],
    implemented: false
  },
  {
    id: "8",
    name: "Qualitätsanforderungen",
    goal: "old/qualitätsanforderungen",
    children: [],
    implemented: false
  },
  {
    id: "9",
    name: "Vorgaben",
    goal: "old/vorgaben",
    children: [],
    implemented: false
  },
  {
    id: "10",
    name: "Datenmodell",
    goal: "old/datenmodell",
    children: [],
    implemented: false
  },
  {
    id: "11",
    name: "Finanzkennzahlen",
    goal: "old/finanzkennzahlen",
    children: [],
    implemented: true
  }
];

const nav_items = [
  {
    text: "Main",
    disabled: false,
    to: { path: "/main" }
  },
  {
    text: "Main(old)",
    disabled: false,
    to: { path: "main" }
  },
  {
    text: "Architekturerfassung",
    disabled: false,
    to: { path: "architekturErfassung" }
  },
  {
    text: "Architekturnavigation",
    disabled: true,
    to: { path: "architekturNavigation" }
  }
];
export default {
  name: "Home",
  components: { TopBar },
  props: {},
  data() {
    return {
      user: this.$store.getters.getUser,
      active_system_bezeichnung: "",
      tree_nav: treeContent,
      nav: nav_items
    };
  },
  async created() {
    this.active_system_bezeichnung = this.$store.getters.currentSystemBezeichnung;
  },
  methods: {
    goto(item) {
      try {
        const selectedNavItem = this.findInList(treeContent, "id", item)[0];
        if (selectedNavItem) {
          const selPath = "/" + selectedNavItem.goal;
          if (
            selectedNavItem.implemented == true &&
            this.$route.path !== selPath
          ) {
            //this.$router.push(path)
            this.$router.push({ path: selPath });
          }
        }
      } catch (error) {
        console.log("cannot find nav item");
      }
    },
    findInList(obj, key, val) {
      var retVal = [];
      for (var i in obj) {
        if (typeof obj[i] == "object") {
          retVal = retVal.concat(this.findInList(obj[i], key, val));
        } else if (i == key && obj[i] == val) {
          retVal.push(obj);
        }
      }
      return retVal;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
